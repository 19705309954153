<template>
  <div class="wrapper">
    <div class="title">临淄区智慧养老大数据平台</div>
    <div class="import">
      <el-button type="primary" size="mini" icon="el-icon-upload2">
        <a href="/static/汇总数据.zip" download="汇总数据.zip">汇总导出 </a>
      </el-button>
    </div>
    <div class="moduleLink">
      <div class="moduleLinkBox" v-for="(item, index) in moduleList" :key="index" @click="linkModule(item)">
        <span>{{ item.name }}</span>
      </div>
    </div>
    <div class="gridContainer" style="height: calc(100% - 3.9rem)">
      <div v-for="(item, index) in webConfig.layOutConfig" :key="index" :style="item.options.style" class="default">
        <component :ref="item.ref" :options="item.options" :is="setPage(item).component"></component>
      </div>
    </div>
    <!-- 供子级调用的组件集合 -->
    <!-- <div v-for="item in specialModes" :key="item.ref">
            <component :ref="item.ref" :queryData="item.queryData" :options="item.options" :is="item.component"></component>
        </div> -->
    <!-- 地图 -->
    <div style="height: 100%; width: 100%; position: absolute">
      <LargeScreenMap />
    </div>
    <div class="select">
      <el-cascader v-model="qds" :options="options"></el-cascader>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import LargeScreenMap from "../../map/viewers/LargeScreenViewer.vue";
export default {
  name: "DP",
  components: { LargeScreenMap },
  data() {
    return {
      moduleList: [
        {
          name: "助餐管理",
          url: "/mealGl/lrxx"
        },
        {
          name: "居家养老服务监管",
          url: "/JJYLFWJGNEW/zzfwgl"
        },
        {
          name: "适老化设备管理",
          url: "/JTYLCWGL/fwzzgl"
        },
        {
          name: "社区养老服务设施监管",
          url: "/visitElderly/index"
        },
        {
          name: "辅具租赁管理",
          url: "/FJZLGL/index"
        },
        {
          name: "临淄慈善",
          url: "/CS/lzcs"
        },
      ],
      //供子级调用的组件集合
      specialModes: [],
      isShow: true,
      options: [
        {
          label: "淄博市",
          value: "qds",
        },
      ],
      qds: "qds",
    };
  },
  computed: {
    ...mapState(["webConfig"]),
    userName() {
      return this.$store.getters.user.userName;
    },
    //获取渔船动态监管子系统的动态组件
    businessArray() {
      return this.$store.getters.webConfig.businessArray;
    },
  },
  provide() {
    return {
      showComponent: this.showComponent,
    };
  },
  created() { },
  mounted() { },
  destroyed() { },
  methods: {
    linkModule(item) {
      console.log(item)
      this.$router.push(item.url)
    },
    setPage(item) {
      const page = this.$getTypeName(
        this.webConfig.pageArr,
        "ref",
        item.code ? item.code : "empty",
        {}
      );
      return page;
    },
    /**
     *  生成并展示供子级调用的组件集合
     * @param queryData:给子组件传递的参数
     * @param options:子组件的样式配置
     * @param checkRepeat:true-则不能生成多个同样的组件,false-可以生成多个同样的组件
     **/
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/homeCopy.scss";

.title {
  height: 6%;
  line-height: 4rem !important;
  font-size: 1.8rem !important;
  color: white !important;
  z-index: 99;
}

.import {
  position: absolute;
  top: 10px;
  right: 20px;
  z-index: 100;
}

.ctitle {
  // background-image: url("~@/assets/home/title_bg.png");
  background-size: 100% 100%;
  color: #fff;
  position: relative;
}

.icImage {
  height: 70%;
  width: 2rem;
  background-size: 100% 100%;
  margin-right: 0.5rem;
  // background-image: url("~@/assets/home/ic_title.png");
}

.select {
  position: absolute;
  bottom: 18rem;
  left: 0;
  right: 0;
  margin: auto;
  width: 18.63rem;
  height: 5.13rem;
  background-image: url("~@/assets/content/qdsbg.png");
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

/deep/ .el-input__inner {
  color: #fff;
  background-color: rgba(0, 0, 0, 0);
  font-size: 1.25rem;
  font-family: DOUYU, DOUYU-常规体;
  font-weight: normal;
  text-align: center;
  color: #ffffff;
  line-height: 1.56rem;
  border: 0px;
}

/deep/ .el-dropdown-menu {
  top: 0.6rem !important;
}

.moduleLink {
  width: 97%;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  z-index: 99999;
  display: flex;
  justify-content: space-around;
  height: 35px;
  line-height: 35px;
  background: url('~@/assets/content/bakbottom2.pic.jpg');
  // background-size: 100% 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 2px;

  .moduleLinkBox {
    cursor: pointer;

    span {
      color: #fff;
      font-size: 1.5rem;
    }
  }
}
</style>
